<script>
import { authUserMethods } from  "../../../state/helpers/authuser"
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";


/**
 * Forgot password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      password: "",
      confirmed_password: "",
      error: null,
      tryingToReset: false,
      isResetError: false,
      token: "",
      submitted: false,
      isAuthError: false,
      authError: null,
    };
  },
  validations: {
    password: {
      required,
    },
    confirmed_password: {
      required,
    }
    
  },
  mounted() {
    this.token = this.$route.query.token;
    // eslint-disable-next-line no-console
  },
  
  methods: {
    ...authUserMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const email = localStorage.getItem('reset_email');
        const data = {email, token: this.token, password: this.password, password_confirmation: this.confirmed_password}
        this.resetPassword(data).then(() => {
          this.password = ""
          this.confirmed_password = ""
          this.token = ""
          localStorage.removeItem("reset_email")
        });
      }
      
    }
  }
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="">
              <div class="text-primary text-center p-4">
                <h5 class="text-success font-size-20 p-2">Safe and Sound Real Estate Inc</h5>
                <p class="text-dark"> Set New Password, It must be at least 8 characters</p>
                <!-- <h3><a href="/" class="logo logo-admin text-orange">SRA </a></h3> -->
              </div>
            </div>
            <div class="card-body p-4" style="margin-top:-60px !important;">
              <div class="p-3">
                <b-alert v-model="isResetError" class="mt-3" variant="danger" dismissible>{{error}}</b-alert>

                <b-form @submit.prevent="tryToReset" class="form-horizontal mt-4">
                  <b-form-group id="password-group" label="New Password" label-for="password" class="mb-3" label-class="form-label">
                    <b-form-input id="password" v-model="password" :class="{ 'is-invalid': submitted && $v.email.$error }" type="password" placeholder="Enter your new password"></b-form-input>
                    <div
                      v-if="submitted && $v.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.password.required">Password is required.</span>
                      <span v-if="!$v.password"
                        >Please enter valid password.</span
                      >
                    </div>
                  </b-form-group>
                  <b-form-group id="password-confirm-group" label="Confirm Password" label-for="email" class="mb-3" label-class="form-label">
                    <b-form-input id="confirmed_password" :class="{ 'is-invalid': submitted && $v.email.$error }" v-model="confirmed_password" type="password" placeholder="Enter your confirmed password"></b-form-input>
                    <div
                      v-if="submitted && $v.confirmed_password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.confirmed_password.required">confirmed_password is required.</span>
                      <span v-if="!$v.confirmed_password"
                        >Please enter valid confirmed_password.</span
                      >
                    </div>
                  </b-form-group>
                  <div class="row mb-0">
                    <div class="col-12 text-end">
                      <b-button type="submit" variant="outline-orange" class="w-md">Reset</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
             
              <router-link to="/login" class="fw-medium text-primary">Login</router-link>
            </p>
            <p class="mb-0">
              ©{{ new Date().getFullYear() }} Safe and Sound Real Estate Inc
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
